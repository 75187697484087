<template>
  <div class="user">
    <top />
    <div class="container margin">
      <side-nav>
        <div class="user__top flex flex__space-between">
          <div class="user__left">
            <a-breadcrumb separator=">">
              <a-breadcrumb-item>个人中心</a-breadcrumb-item>
              <a-breadcrumb-item>我的分享</a-breadcrumb-item>
              <a-breadcrumb-item>{{data.title}}</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <div class="user__right" @click="back">
            <a-icon type="left" />返回
          </div>
        </div>
        <div class="user__content" v-html="data.content"></div>
        <div class="review">
          <template v-if="data.reviewStatus==='REJECT'">拒绝原因：{{data.rejectReason}}</template>
        </div>
        <div class="user__bottom flex flex__end">
          <div class="user__like" @click="likeFun">
            <a-icon type="like" />
            <span class="user__num">{{data.likeCount}}</span>
            赞
          </div>
          <div class="user__comment">
            <a-icon type="message" />
            <span class="user__num">{{data.commentCount}}</span>
            评论
          </div>
        </div>
        <div class="user__msg">
          <div class="user__msg-number">
            <span class="user__msg-number_bold">{{data.commentCount}}</span>
            条评论
          </div>
          <div
            class="user__msg-item flex flex__space-between flex__align-center"
            v-for="(item, index) in data1"
            :key="index"
          >
            <div class="user__msg__left flex flex__align-center">
              <img :src="getFileAccessHttpUrl(item.userAvatar)" alt class="user__msg-img" />
              <div class="user__msg-text">
                <span class="user__msg-user">{{item.userName}}</span>
                ：
                {{item.content}}
              </div>
            </div>
            <div class="user__msg-date">{{item.createTime}}</div>
          </div>
        </div>
      </side-nav>
    </div>
  </div>
</template>

<script>
const data1 = [];
import {
  articleQueryById,
  articleCommentPage
  // addArticleComment,
  // articleEdit
} from "@/api/community.js";
import sideNav from "@/components/side-nav";
import top from "../../components/top.vue";
import { getFileAccessHttpUrl } from "@/utils/tools";
export default {
  components: { sideNav, top },
  data() {
    return {
      value: "",
      data: "<span>我是文章详情页</span>",
      data1
      // msgData
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return avatar => {
        return getFileAccessHttpUrl(avatar)
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  },
  methods: {
    getData() {
      this.getArticle();
      this.getComment();
    },
    // 获取文章详情
    getArticle() {
      articleQueryById({
        id: this.$route.query.id
      }).then(res => {
        this.data = res.result;
        console.log(this.data, "qqq");
      });
    },
    // 获取评论
    getComment() {
      articleCommentPage({
        articleId: this.$route.query.id
      }).then(res => {
        this.data1 = res.result.records;
      });
    },
    likeFun() {
      console.log("点赞111");
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang = "scss" scoped>
.user {
  &__top {
    padding: 32px 24px;
    border-bottom: 1px solid #e4e4e4;
  }
  &__content {
    padding-top: 67px;
  }
  &__bottom {
    padding: 0 24px 18px;
    margin-top: 5px;
    border-bottom: 1px solid #e4e4e4;
    font-size: 14px;
    color: #666666;
  }
  .review {
    margin-top: 10px;
    font-size: 14px;
    /* color: red; */
  }
  &__like {
    margin-right: 20px;
    cursor: pointer;
  }
  &__comment {
    cursor: pointer;
  }
  &__num {
    margin-left: 6px;
    color: #3b77ff;
  }
  &__right {
    cursor: pointer;
    &:hover {
      color: #3b77ff;
    }
  }
  &__msg {
    &-btn {
      width: 70px;
      height: 73px;
      background: #c4a468;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: #fff;
      cursor: pointer;
    }
    &-item {
      padding: 20px 0;
      border-bottom: 1px solid #e4e4e4;
    }
    &__left {
      width: 688px;
    }
    &-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin-right: 12px;
    }
    &-text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    &-user {
      color: #3b77ff;
      font-size: 14px;
      font-weight: 400;
    }
    &-date {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    &-number {
      font-size: 14px;
      color: #333333;
      margin-top: 17px;
      &_bold {
        font-weight: bold;
      }
    }
  }
}
.mtb16 {
  margin: 16px 0;
}
</style>
<style >
 .user__content img{
  width: 100%;
  height:auto;
}
</style>
